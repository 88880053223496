import React from "react";

import {MainLayout} from "../components/layout"
import {SectionHeader} from "./index";

import shared from "../styles/shared.module.styl";
import noticias from "../styles/noticias.module.styl"
import { Helmety } from "../components/Head";

export default class Noticias extends React.Component {
  render () {

    return (
      <MainLayout>
        <Helmety
          url="https://www.dharmabooks.com.mx/noticias/"
          title="Noticias - Dharma Books"
          content="IV Premio Narrativa Casa Wabi - Dharma Books"
          imgURL="https://www.dharmabooks.com.mx/static_resources/wabi-poster-iv.png"
        />
        <div className={shared.container} style={{display: 'flex', justifyContent: 'space-evenly'}}>
          <div className={shared.content}>
            <SectionHeader header="IV Premio Narrativa Casa Wabi - Dharma Books" />
            <div className={noticias.wabiContainer}>
              <a href="https://www.dharmabooks.com.mx/static_resources/bases-wabi-iv.pdf"
                 alt="IV Premio Narrativa Casa Wabi - Dharma Books"
                 target="_blank" rel="noopener noreferrer"
                 className={noticias.basesLink}>
                Consulta las bases en PDF aquí
              </a>
              <img src="https://www.dharmabooks.com.mx/static_resources/wabi-poster-iv.png"
                   alt="IV Premio Narrativa Casa Wabi - Dharma Books" />
            </div>
          </div>
        </div>
      </MainLayout>
    )
  }
}

